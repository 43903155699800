import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useSearchParams, Navigate } from "react-router-dom";
import { useStateContext } from "contexts/auth-context";
import { FormProvider, useForm } from "react-hook-form";
import CustomizedTab from "components/Custom/CustomizedTab";
import BreadcrumbsLayout from "components/UI/BreadcrumbsLayout";
import { IBreadcrumbsAndMenu, ITab } from "types/global";
import {
  vehicleSchema,
  vehicleValidation,
} from "components/Form/Logistic/Vehicle/schema";
import { IVehicle } from "types/Logistic/vehicle";
import DocumentInfoTab from "./DocumentInfoTab";
import MaintenanceTab from "./MaintenanceTab";
import { ActivityLogDocumentType } from "generated/sales";
import { useActivityLog } from "hooks/use-activity-log";
import { ActivityLog } from "components/UI/ActivityLog";
import {
  VehicleFindUniqueQuery,
  useVehicleFindUniqueQuery,
} from "generated/vehicle";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import TireHistoryTab from "./HistoryTab";

// import { ActivityLogSlideInPanel } from "../../../components/UI/SlideInPanel/ActivityLogSlideInPanel";
// import { ActivityLogDocumentType } from "../../../generated/inventory";
// import { IPurchaseRequest } from "../../../types/Purchase/purchaseRequest";

const VehicleContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const {
    state: { permissions },
  } = useStateContext();

  const methods = useForm<IVehicle>({
    defaultValues: { ...vehicleSchema },
    resolver: yupResolver<any>(vehicleValidation),
  });

  const { openActivityLog, onActivityLogOpen, onActivityLogClose } =
    useActivityLog();

  const graphQLClient = createGraphQLClientWithMiddleware("sales");
  const { data } = useVehicleFindUniqueQuery<VehicleFindUniqueQuery>(
    graphQLClient,
    {
      vehicleFindUniqueId: id ? parseInt(id) : -1,
    },
    {
      enabled: !!id,
      staleTime: Infinity,
    }
  );

  const tabs: ITab[] = [
    {
      label: t("logistic.vehicle.document_info"),
      path: `${pathname}`,
    },
    id
      ? {
          label: t("logistic.tire.history.index"),
          path: `${pathname}?tab=history`,
        }
      : undefined,
    id
      ? {
          label: t("logistic.vehicle.maintenance"),
          path: `${pathname}?tab=maintenance`,
        }
      : undefined,
  ].filter(Boolean) as ITab[];

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "history":
        return <TireHistoryTab />;
      case "maintenance":
        return <MaintenanceTab />;
      default:
        return <DocumentInfoTab />;
    }
  };

  const currentTab = pathname + (tab ? `?tab=${tab}` : "");

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("logistic.index"),
      to: "/logistic",
    },
    {
      name: t("logistic.vehicle.index"),
      to: "/logistic/vehicle",
    },
    {
      name: id
        ? data?.VehicleFindUnique?.unique_id || "-"
        : t("logistic.vehicle.vehicle_create"),
    },
  ];

  if (permissions && !permissions.vehicle?.view) {
    return <Navigate to="/unauthorized" replace />;
  }

  return (
    <FormProvider {...methods}>
      <BreadcrumbsLayout
        breadcrumbs={breadcrumbs}
        onActivityLogOpen={onActivityLogOpen}
      />
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider />
      {renderTab(tab)}
      {id && (
        <ActivityLog
          open={openActivityLog}
          onClose={onActivityLogClose}
          documentId={id!}
          documentType={ActivityLogDocumentType.Vehicle}
        />
      )}
    </FormProvider>
  );
};

export default VehicleContainer;
