import { Box, Skeleton, Typography } from "@mui/material";
import {
  useCompanyFindUniqueQuery,
  CompanyFindUniqueQuery,
} from "generated/general";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";

type Props = {
  branch_unique_id: string;
};

const PDFTransportInfo = ({ branch_unique_id }: Props) => {
  const graphQLClientWithHeaderCompany: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isLoading } = useCompanyFindUniqueQuery<CompanyFindUniqueQuery>(
    graphQLClientWithHeaderCompany,
    {
      uniqueInput: { id: parseInt(branch_unique_id) },
    }
  );

  const fontSize = 10.66;

  if (isLoading) {
    return <Skeleton animation="wave" />;
  }

  return (
    <Box display={"flex"} alignItems={"center"}>
      <Box sx={{ height: "72px", width: "72px", mr: 2 }}>
        <img
          alt="appa-logo"
          src={
            data?.CompanyFindUnique?.img_url
              ? data?.CompanyFindUnique.img_url[0]
                ? data?.CompanyFindUnique.img_url[0]
                : "/static/logo.png"
              : "/static/logo.png"
          }
          className="image-no-hover"
          style={{ height: "72px", width: "72px" }}
        />
      </Box>
      <Box>
        <Typography fontSize={16} fontWeight={600} lineHeight={2}>
          บริษัท แอปป้า ทรานสปอร์ต จำกัด
        </Typography>
        <Typography fontSize={fontSize}>
          738/39, 738/41 ซ. วัดจันทร์ใน แขวงบางคอแหลม เขตบางคอแหลม กรุงเทพมหานคร
          10120
        </Typography>
        <Box display={"flex"} gap={0.5}>
          <Typography fontSize={fontSize} fontWeight={600} lineHeight={2}>
            เลขประจำตัวผู้เสียภาษี
          </Typography>
          <Typography fontSize={fontSize} lineHeight={2}>
            0105557067522
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PDFTransportInfo;
