import {
  ColDef,
  ValueFormatterParams,
  ICellRendererParams,
} from "ag-grid-community";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "utils/Date";
import { dateFilterParams } from "utils/AgGridFilter";
import { statusValueFormatter } from "utils/Formatter/Global";

import CustomizedStatus from "components/Custom/CustomizedStatus";

import { formatNumber } from "utils/Global";
import { typeStatusValueFormatter } from "utils/Formatter/Logistic/Vehicle";

export const useTireHistoryColumnDefs = () => {
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const { t } = useTranslation();
  useEffect(() => {
    setColumnDefs([
      {
        field: "tire_notification_status",
        headerName: t("logistic.tire.history.tire_status"),
        filter: "agSetColumnFilter",
        cellRenderer: (params: ICellRendererParams<any, string>) => (
          <CustomizedStatus status={params.value} />
        ),
        cellStyle: {
          display: "flex",
          justifycontent: "center",
          alignItems: "center",
        },
        filterParams: {
          values: ["normal", "approaching", "overdue"],
          valueFormatter: (params: ValueFormatterParams) =>
            typeStatusValueFormatter(params.value),
        },
      },
      {
        field: "tire_series",
        headerName: t("logistic.tire.serial"),
        filter: "agTextColumnFilter",
      },
      {
        field: "action_type",
        headerName: t("logistic.tire.history.action_type"),
        filter: "agSetColumnFilter",
        valueFormatter: (params: ValueFormatterParams) =>
          params.value === "install"
            ? "ใส่ยาง"
            : params.value === "uninstall"
            ? "ถอดยาง"
            : "เปลี่ยนตำแหน่ง",
        filterParams: {
          values: ["install", "uninstall", "change_position"],
          valueFormatter: (params: ValueFormatterParams) =>
            params.value === "install"
              ? "ใส่ยาง"
              : params.value === "uninstall"
              ? "ถอดยาง"
              : "เปลี่ยนตำแหน่ง",
        },
      },
      {
        field: "previous_position",
        headerName: t("logistic.tire.history.previous_position"),
        filter: "agSetColumnFilter",
        valueFormatter: (params) =>
          params.value === "spare" ? "สำรอง" : params.value || "-",
        filterParams: {
          values: [
            "(Blank)",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "spare",
          ],
          valueFormatter: (params: ValueFormatterParams) =>
            params.value === "spare" ? "สำรอง" : params.value,
          comparator: (a: any, b: any) => {
            const specialOrder = ["สำรอง", "(Blank)"];

            const isASpecial = specialOrder.includes(a);
            const isBSpecial = specialOrder.includes(b);

            // Handle cases where one or both are special strings
            if (isASpecial && isBSpecial) {
              // Both are special, sort by their index in specialOrder
              return specialOrder.indexOf(a) - specialOrder.indexOf(b);
            }
            if (isASpecial) return 1; // `a` is special, place it after numbers
            if (isBSpecial) return -1; // `b` is special, place it after numbers

            // Default: Parse numbers and compare
            const valA = parseInt(a, 10);
            const valB = parseInt(b, 10);

            if (!isNaN(valA) && !isNaN(valB)) {
              // Both are numbers, compare normally
              return valA - valB;
            }

            // If one value is numeric and the other is not, treat numeric as smaller
            if (!isNaN(valA)) return -1; // `a` is numeric, place it first
            if (!isNaN(valB)) return 1; // `b` is numeric, place it first

            // Default fallback for other string cases (not expected in this use case)
            return 0;
          },
        },
        cellStyle: {
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
        },
      },
      {
        field: "current_position",
        headerName: t("logistic.tire.history.current_position"),
        filter: "agSetColumnFilter",
        valueFormatter: (params) =>
          params.value === "spare" ? "สำรอง" : params.value || "-",
        filterParams: {
          values: [
            "(Blank)",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "spare",
          ],
          valueFormatter: (params: ValueFormatterParams) =>
            params.value === "spare" ? "สำรอง" : params.value,
          comparator: (a: any, b: any) => {
            const specialOrder = ["สำรอง", "(Blank)"];

            const isASpecial = specialOrder.includes(a);
            const isBSpecial = specialOrder.includes(b);

            // Handle cases where one or both are special strings
            if (isASpecial && isBSpecial) {
              // Both are special, sort by their index in specialOrder
              return specialOrder.indexOf(a) - specialOrder.indexOf(b);
            }
            if (isASpecial) return 1; // `a` is special, place it after numbers
            if (isBSpecial) return -1; // `b` is special, place it after numbers

            // Default: Parse numbers and compare
            const valA = parseInt(a, 10);
            const valB = parseInt(b, 10);

            if (!isNaN(valA) && !isNaN(valB)) {
              // Both are numbers, compare normally
              return valA - valB;
            }

            // If one value is numeric and the other is not, treat numeric as smaller
            if (!isNaN(valA)) return -1; // `a` is numeric, place it first
            if (!isNaN(valB)) return 1; // `b` is numeric, place it first

            // Default fallback for other string cases (not expected in this use case)
            return 0;
          },
        },
        cellStyle: {
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
        },
      },
      {
        field: "action_date",
        headerName: t("logistic.tire.history.action_date"),
        filter: "agDateColumnFilter",
        filterParams: dateFilterParams,
        sort: "desc",
        valueFormatter: (params: ValueFormatterParams) =>
          formatDate(params.value),
        flex: 1,
        minWidth: 200,
      },
      {
        field: "action_distance",
        headerName: t("logistic.tire.history.action_distance"),
        filter: "agNumberColumnFilter",
        valueFormatter: (params: ValueFormatterParams) =>
          params.value.toLocaleString(),
        cellStyle: {
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
        },
        headerClass: "ag-end-header",
      },
      {
        field: "tire_distance",
        headerName: t("logistic.tire.current_distance"),
        filter: "agNumberColumnFilter",
        valueFormatter: (params: ValueFormatterParams) =>
          params.value.toLocaleString(),
        cellStyle: {
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
        },
        headerClass: "ag-end-header",
      },
      {
        field: "due_distance",
        headerName: t("logistic.tire.due_distance"),
        filter: "agNumberColumnFilter",
        valueFormatter: (params: ValueFormatterParams) =>
          params.value.toLocaleString(),
        cellStyle: {
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
        },
        headerClass: "ag-end-header",
      },
      {
        field: "usage_distance",
        headerName: t("logistic.tire.history.usage_distance"),
        filter: "agNumberColumnFilter",
        valueFormatter: (params: ValueFormatterParams) =>
          params.value.toLocaleString(),
        cellStyle: {
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
        },
        headerClass: "ag-end-header",
      },
      {
        field: "price_per_unit",
        headerName: t("logistic.tire.price_per_unit"),
        filter: "agNumberColumnFilter",
        valueFormatter: (params: ValueFormatterParams) =>
          formatNumber(params.value || 0),
        cellStyle: {
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
        },
        headerClass: "ag-end-header",
      },
      {
        field: "band",
        headerName: t("logistic.tire.band"),
        filter: "agTextColumnFilter",
      },
      {
        field: "model",
        headerName: t("logistic.tire.model"),
        filter: "agTextColumnFilter",
      },
      {
        field: "vehicle_unique_id",
        headerName: t("logistic.tire.history.vehicle_unique_id"),
        filter: "agTextColumnFilter",
      },
      {
        field: "vehicle_license",
        headerName: t("logistic.tire.history.vehicle_license"),
        filter: "agTextColumnFilter",
      },
      {
        field: "remark",
        headerName: t("logistic.tire.history.remark"),
        filter: "agTextColumnFilter",
      },
      {
        field: "tire_status",
        headerName: t("logistic.tire.history.tire_notification_status"),
        filter: "agSetColumnFilter",
        cellRenderer: (params: ICellRendererParams<any, string>) => (
          <CustomizedStatus status={params.value} />
        ),
        cellStyle: {
          display: "flex",
          justifycontent: "center",
          alignItems: "center",
        },
        filterParams: {
          values: ["stock", "active", "in_active"],
          valueFormatter: (params: ValueFormatterParams) =>
            statusValueFormatter(params.value),
        },
      },
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);
  return columnDefs;
};
