import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStateContext } from "contexts/auth-context";
import { useNavigate, useParams } from "react-router-dom";
import { useFormContext, useWatch } from "react-hook-form";

import { useDisable } from "hooks/use-disable";
import { useConfirmation } from "hooks/use-confirmation";
import { useBillingNoteOption } from "hooks/Sales/BillingNote/use-billing-note-option";

import {
  Box,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

import EmployeeListForm from "components/Form/EmployeeList";
import Confirmation from "components/UI/Confirmation";
import CustomizedStatus from "components/Custom/CustomizedStatus";
import { CustomizedTooltip } from "components/Custom/CustomizedTooltip";
import CustomizedMenuOptions from "components/Custom/CustomizedMenuOptions";
import ControlledTextField from "components/Controller/ControlledTextField";
import ControlledDatePicker from "components/Controller/ControlledDatePicker";
import ControlledNumberTextField from "components/Controller/ControlledNumberTextField";

import { IMenuOption } from "types/global";
import { IBillingNote } from "types/Sales/billing-note";
import {
  SalesModelType,
  SalesUniqueIdGenerateQuery,
  useSalesUniqueIdGenerateQuery,
} from "generated/sales";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";
import { copyBillingNoteFormatter } from "utils/Formatter/Sales/BillingNote";

import CancelConfirmation from "components/UI/CancelConfirmation";
import ControlledSelect from "components/Controller/ControlledSelect";

type Props = {
  editClickHandler: () => void;
  cancelHandler?: () => Promise<void>;
};

const BillingNoteHeader = ({ cancelHandler, editClickHandler }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [disabled] = useDisable();

  const {
    state: { authUser, permissions },
  } = useStateContext();

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<IBillingNote>();

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const printOptions: IMenuOption[] = [
    {
      value: `พิมพ์${t("sales.billing_note.index")}`,
      disabled: !id,
    },
  ];

  const graphQLClient = createGraphQLClientWithMiddleware("sales");

  const { refetch: refetchUniqueId } =
    useSalesUniqueIdGenerateQuery<SalesUniqueIdGenerateQuery>(
      graphQLClient,
      {
        modelType: SalesModelType.BillingNote,
      },
      {
        enabled: false,
      }
    );

  const generateUniqueId = useCallback(async () => {
    const { data } = await refetchUniqueId();
    setValue("unique_id", data?.UniqueIdGenerate ?? "");
  }, [refetchUniqueId, setValue]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const selectModifyOptions = useBillingNoteOption(
    status,
    disabled,
    permissions?.billing_note
  );

  const copyDocumentHandler = async () => {
    const order = getValues();

    navigate("/sales/billing-note/add", {
      state: await copyBillingNoteFormatter(order, authUser),
    });
  };

  const {
    confirmation: copyConfirmation,
    // openConfirmationHandler: openCopyConfirmation,
    closeConfirmationHandler: closeCopyConfirmation,
    submitConfirmationHandler: submitCopyConfirmation,
  } = useConfirmation(copyDocumentHandler);

  const {
    confirmation: cancelConfirmation,
    openConfirmationHandler: openCancelConfirmation,
    closeConfirmationHandler: closeCancelConfirmation,
    submitConfirmationHandler: submitCancelConfirmation,
  } = useConfirmation(cancelHandler);

  return (
    <Box maxWidth={1040} mt={2}>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">
              {t("sales.billing_note.index")}
            </Typography>
            <CustomizedStatus status={status} />
          </Box>
        </Grid>
        {id && (
          <Grid item sm={12} md={6}>
            <Grid
              container
              spacing={1.5}
              justifyContent="end"
              alignItems="center"
            >
              {permissions?.billing_note?.view && status !== "cancelled" && (
                <Grid item sm={5.5} md={4}>
                  <CustomizedMenuOptions
                    fullWidth
                    size="medium"
                    label={"พิมพ์เอกสาร"}
                    options={printOptions}
                    onSelect={(e) => {
                      const value = e.target as HTMLElement;
                      if (
                        value.innerText ===
                        `พิมพ์${t("sales.billing_note.index")}`
                      ) {
                        navigate(`/sales/billing-note/${id}/pdf`);
                      }
                    }}
                    disabled={!id}
                  />
                </Grid>
              )}
              {selectModifyOptions?.length > 0 &&
                status !== "cancelled" &&
                status !== "fully_payment" && (
                  <Grid item sm={5.5} md={4}>
                    <CustomizedMenuOptions
                      fullWidth
                      size="medium"
                      label={"ตัวเลือก"}
                      options={selectModifyOptions}
                      onSelect={(e) => {
                        const value = e.target as HTMLElement;
                        switch (value.innerText) {
                          case "แก้ไข":
                            editClickHandler();
                            break;
                          // case "คัดลอก":
                          //   openCopyConfirmation();
                          //   break;
                          case "ยกเลิก":
                            openCancelConfirmation();
                            break;
                          default:
                            break;
                        }
                      }}
                      disabled={!id}
                    />
                  </Grid>
                )}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container spacing={1.5} alignItems="center">
            <Grid item xs={12} sm={12} md={3}>
              <Typography fontWeight={600}>{t("sales.unique_id")}</Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <ControlledTextField
                name="unique_id"
                control={control}
                placeholder={t("sales.unique_id")}
                error={Boolean(errors?.unique_id)}
                helperText={
                  errors?.unique_id && errors?.unique_id.message?.toString()
                }
                disabled={Boolean(id)}
                viewMode={disabled && status !== "draft"}
                required
              />
            </Grid>
            <Grid item xs={1}>
              {!Boolean(id) && (
                <CustomizedTooltip
                  title="เรียกเลขที่เอกสารใหม่"
                  enterNextDelay={200}
                >
                  <IconButton onClick={generateUniqueId} color="primary">
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Typography fontWeight={600}>
                {t("sales.company_type")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <ControlledSelect
                name="company_type"
                control={control}
                options={[
                  { value: "forwarder", label: "ฟอร์เวิดเดอร์" },
                  { value: "transport", label: "ทรานสปอร์ต" },
                ]}
                error={Boolean(errors?.company_type)}
                helperText={
                  errors?.company_type &&
                  errors?.company_type.message?.toString()
                }
                sx={{ minWidth: "130px" }}
                viewMode={disabled && status !== "draft"}
                required
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Typography fontWeight={600}>
                {t("sales.employee_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={7}>
              <Box>
                <EmployeeListForm billingNote />
              </Box>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={isSmall ? 2 : 0}>
          <Grid container spacing={1.5} justifyContent="flex-end">
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                label={t("date.created_date")}
                disabled={true}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="issue_date"
                control={control}
                label={t("date.issue_date")}
                disabled={disabled || status === "wait_accept"}
                viewMode={disabled}
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={1.5} justifyContent="flex-end" mt={0}>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledDatePicker
                name="due_date"
                control={control}
                label={t("date.due_date")}
                disabled={disabled}
                viewMode={disabled}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ControlledNumberTextField
                name="credit_day"
                control={control}
                label={t("sales.credit_day")}
                disabled={disabled}
                viewMode={disabled}
                decimalScale={0}
                textAlign="left"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CancelConfirmation
        uniqueId={getValues("unique_id") || ""}
        confirmation={cancelConfirmation}
        closeConfirmationHandler={closeCancelConfirmation}
        submitConfirmationHandler={submitCancelConfirmation}
      />
      <Confirmation
        title="ยืนยันหากต้องการคัดลอกเอกสาร"
        open={copyConfirmation}
        handleClose={closeCopyConfirmation}
        action={submitCopyConfirmation}
      />
    </Box>
  );
};

export default BillingNoteHeader;
