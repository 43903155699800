import { Box, Grid, Typography } from "@mui/material";
import CustomizedStatus from "components/Custom/CustomizedStatus";
import ControlledSelect from "components/Controller/ControlledSelect";
import DeleteConfirmation from "components/UI/Modal/DeleteConfirmation";
import ControlledTextField from "components/Controller/ControlledTextField";
import CustomizedMenuOptions from "components/Custom/CustomizedMenuOptions";
import { useStateContext } from "contexts/auth-context";
import CustomizedReminderVehicleBox, {
  reminderType,
} from "components/Custom/CustomizedReminderVehicleBox";

import { SnackbarKey } from "notistack";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IVehicle } from "types/Logistic/vehicle";
import { IMenuOption, ISelectOption } from "types/global";
import { useFormContext, useWatch } from "react-hook-form";

import EmployeeListForm from "../../EmployeeList";

import { useDisable } from "hooks/use-disable";
import { useConfirmation } from "hooks/use-confirmation";

type Props = {
  data: IVehicle;
  editClickHandler: () => void;
  deleteHandler: () => Promise<SnackbarKey | undefined>;
};

const VehicleHeader = ({ data, editClickHandler, deleteHandler }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    state: { permissions },
  } = useStateContext();

  const [disable] = useDisable();

  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<IVehicle>();

  const type = useWatch({
    control,
    name: "type",
  });

  const selectModifyOptions: IMenuOption[] = [
    permissions?.vehicle?.update && {
      value: "แก้ไข",
      disabled: Boolean(!disable && id) || false,
    },
    permissions?.vehicle?.delete && {
      value: "ลบ",
      disabled: false,
    },
  ].filter(Boolean) as IMenuOption[];

  const typeOptions: ISelectOption[] = [
    {
      label: "4 ล้อ",
      value: "four_wheel",
      disabled: false,
    },
    {
      label: "6 ล้อ",
      value: "six_wheel",
      disabled: false,
    },
    {
      label: "10 ล้อ",
      value: "ten_wheel",
      disabled: false,
    },
    {
      label: "หัวลาก",
      value: "head",
      disabled: false,
    },
    {
      label: "หางลาก",
      value: "trailer",
      disabled: false,
    },
  ];

  const ratio = type !== "trailer" ? 2.4 : 3;

  const {
    confirmation: deleteConfirmation,
    closeConfirmationHandler: closeDeleteConfirmationHandler,
    openConfirmationHandler: openDeleteConfirmationHandler,
    submitConfirmationHandler: submitDeleteConfirmationHandler,
  } = useConfirmation(deleteHandler);

  return (
    <>
      <Box maxWidth={1040}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography variant="h5">
                {t("logistic.vehicle.index")}
              </Typography>
              {Boolean(id) && <CustomizedStatus status={data?.status} />}
            </Box>
          </Grid>
          {id && selectModifyOptions?.length > 0 && (
            <Grid item sm={12} md={6}>
              <Grid container justifyContent="end">
                <Grid item sm={5.5} md={4}>
                  <CustomizedMenuOptions
                    fullWidth
                    size="medium"
                    label={"ตัวเลือก"}
                    options={selectModifyOptions}
                    onSelect={(e) => {
                      const value = e.target as HTMLElement;
                      switch (value.innerText) {
                        case "แก้ไข":
                          editClickHandler();
                          break;
                        case "ลบ":
                          openDeleteConfirmationHandler();
                          break;
                        default:
                          break;
                      }
                    }}
                    disabled={!Boolean(id)}
                  ></CustomizedMenuOptions>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={1.5}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1.5} alignItems="center">
              <Grid item xs={12} sm={12} md={4}>
                <Typography fontWeight={600}>
                  {t("logistic.vehicle.unique_id")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={11}
                sm={11}
                md={6}
                minHeight={50}
                display="flex"
                alignItems="center"
              >
                <ControlledTextField
                  name="unique_id"
                  control={control}
                  placeholder={t("logistic.vehicle.unique_id")}
                  error={Boolean(errors?.unique_id)}
                  helperText={
                    errors?.unique_id && errors?.unique_id.message?.toString()
                  }
                  disabled={Boolean(id)}
                  viewMode={Boolean(id) && disable}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Typography fontWeight={600}>
                  {"เลข" + t("logistic.vehicle.register_id")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={11}
                sm={11}
                md={6}
                minHeight={50}
                display="flex"
                alignItems="center"
              >
                <ControlledTextField
                  name="register_id"
                  control={control}
                  placeholder={"เลข" + t("logistic.vehicle.register_id")}
                  error={Boolean(errors?.register_id)}
                  helperText={
                    errors?.register_id &&
                    errors?.register_id.message?.toString()
                  }
                  sx={{ backgroundColor: "balck" }}
                  viewMode={Boolean(id) && disable}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Typography fontWeight={600}>
                  {t("logistic.vehicle.type")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={11}
                md={6}
                minHeight={50}
                display="flex"
                alignItems="center"
              >
                <ControlledSelect
                  control={control}
                  name="type"
                  options={typeOptions}
                  error={Boolean(errors.type)}
                  helperText={errors?.type?.message?.toString()}
                  disabled={Boolean(id)}
                  viewMode={Boolean(id) && disable}
                  onChange={() => {
                    setValue("main_tire", []);
                    setValue("spare_tire", []);
                  }}
                  required
                />
              </Grid>
              {type !== "trailer" && (
                <>
                  <Grid item xs={12} sm={12} md={4}>
                    <Typography fontWeight={600}>
                      {t("logistic.vehicle.driver")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    minHeight={50}
                    display="flex"
                    alignItems="center"
                  >
                    <EmployeeListForm
                      isDriver
                      hideCreator
                      disabledLockRow
                      disabledRemoveCreator
                      maxSelectionLimit={2}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          {Boolean(id) && disable && (
            <Grid item xs={12} mt={1}>
              <Grid container spacing={1.5}>
                <Grid item xs={12} sm={6} lg={ratio}>
                  <CustomizedReminderVehicleBox
                    type={reminderType.maintenance}
                    due_distance_kilometer={data.due_distance_kilometer}
                    current_kilometer={data.current_kilometer}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={ratio}>
                  <CustomizedReminderVehicleBox
                    type={reminderType.act_tax}
                    act_end_date={data.act_end_date}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={ratio}>
                  <CustomizedReminderVehicleBox
                    type={reminderType.insurance_vehicle}
                    insurance_vehicle={data.insurance_vehicle}
                  />
                </Grid>
                {type !== "trailer" && (
                  <Grid item xs={12} sm={6} lg={ratio}>
                    <CustomizedReminderVehicleBox
                      type={reminderType.insurance_product}
                      insurance_product={data.insurance_product}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} lg={ratio}>
                  <CustomizedReminderVehicleBox
                    type={reminderType.wheel}
                    vehicleType={type}
                    tire_status={data.tire_status}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
      <DeleteConfirmation
        noDivider
        title={t("logistic.vehicle.sentence.delete_header")}
        message={
          <Box>
            <Typography>
              {t("logistic.vehicle.sentence.delete_message")}
            </Typography>
            <Typography fontWeight="bold">{data?.unique_id}</Typography>
          </Box>
        }
        open={deleteConfirmation}
        handleClose={closeDeleteConfirmationHandler}
        action={submitDeleteConfirmationHandler}
      />
    </>
  );
};

export default VehicleHeader;
