import {
  GridReadyEvent,
  IServerSideGetRowsParams,
  RowDoubleClickedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import AgGrid from "components/UI/AgGrid";
import { GraphQLClient } from "graphql-request";
import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { createGraphQLClientWithMiddleware } from "services/graphqlClient";

import { TIRE_HISTORY_VIEW } from "services/AgGrid/VehicleAgGrid";
import { dateFilterModel } from "utils/AgGridFilter";
import { useTireHistoryColumnDefs } from "./tireHistoryColumnDefs";

type Props = {
  referenceSerial?: string;
  referenceVehicleUniqueId?: string | null;
};

const HistoryTable = ({ referenceSerial, referenceVehicleUniqueId }: Props) => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const navigate = useNavigate();

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const columnDefs = useTireHistoryColumnDefs();

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        created_date,
        created_by_object,
        tire_series,
        vehicle_unique_id,
        action_date,
        current_position,
        previous_position,
        ...otherFilter
      } = filterModel;

      const formatFilter = {
        ...otherFilter,
        tire_series: referenceSerial
          ? {
              type: "contains",
              filterType: "text",
              filter: referenceSerial,
            }
          : tire_series,
        action_date: dateFilterModel(action_date),
        vehicle_unique_id: referenceVehicleUniqueId
          ? {
              type: "contains",
              filterType: "text",
              filter: referenceVehicleUniqueId,
            }
          : vehicle_unique_id,
        created_by: created_by_object,
        current_position: current_position && {
          values: current_position.values.map((value: string) =>
            value === "(Blank)" ? "" : value
          ),
          filterType: "set",
        },
        previous_position: previous_position && {
          values: previous_position.values.map((value: string) =>
            value === "(Blank)" ? "" : value
          ),
          filterType: "set",
        },
        created_date: dateFilterModel(created_date),
      };

      const formatSort = [
        ...sortModel,
        // { colId: "created_date", sort: "desc" },
      ];

      try {
        const { TireUsageHistoryFindViewAggrid } = await graphQLClient.request(
          TIRE_HISTORY_VIEW,
          {
            aggridInput: {
              startRow: startRow,
              endRow: endRow,
              filterModel: formatFilter,
              sortModel: formatSort,
            },
          }
        );

        params.success({
          rowData: TireUsageHistoryFindViewAggrid.results as any[],
          rowCount: TireUsageHistoryFindViewAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback((params: any) => {
    params.api.onFilterChanged();
  }, []);

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { id, tire_id } = params.data;
    navigate(`/logistic/tire/${tire_id || id}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={670}
      onRowDoubleClicked={
        referenceVehicleUniqueId ? undefined : rowSelectHandler
      }
      onGridReady={onGridReady}
      path={`/logistic/tire`}
    />
  );
};

export default HistoryTable;
